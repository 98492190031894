// ColorShadeBlendConvert(percent, color) to make it darker/lighter
// ColorShadeBlendConvert(percent, color1, color2) to make color blend
export default function ColorShadeBlendConvert(prst: number, color0: string, color1?:string, use_log?: boolean) {
    let r,
        g,
        b,
        P,
        f,
        t,
        h,
        i = parseInt,
        m = Math.round,
        a = typeof color1 == "string" ? 1 : 0;

    if (typeof prst != "number" || prst < -1 || prst > 1 || typeof color0 != "string" || (color0[0] != "r" && color0[0] != "#") || (color1 && !a)) {
        return color0;
    }

    const pSBCr = (d:any) => {
        let n = d.length,
            x:{r: number, g: number, b: number, a: number} = {r:0 ,g: 0,b: 0, a: 0};
        if (n > 9) {
            // eslint-disable-next-line
            ([r, g, b, a] = d = d.split(",")), (n = d.length);
            if (n < 3 || n > 4) return color0;
            // eslint-disable-next-line
            (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))), (x.g = i(g)), (x.b = i(b)), (x.a = a ? parseFloat(a.toString()) : -1);
        } else {
            if (n == 8 || n == 6 || n < 4) return color0;
            if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
            d = i(d.slice(1), 16);
            // eslint-disable-next-line
            if (n == 9 || n == 5) (x.r = (d >> 24) & 255), (x.g = (d >> 16) & 255), (x.b = (d >> 8) & 255), (x.a = m((d & 255) / 0.255) / 1000);
            // eslint-disable-next-line
            else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
        }
        return x;
    };
    // eslint-disable-next-line
    (h = color0.length > 9),
        (h = a && color1 ? (color1.length > 9 ? true : color1 == "c" ? !h : false) : h),
        (f = pSBCr(color0)),
        (P = prst < 0),
        (t = color1 && color1 != "c" ? pSBCr(color1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }),
        (prst = P ? prst * -1 : prst),
        (P = 1 - prst);
    if (!f || !t) return color0;
    //@ts-ignore
    //eslint-disable-next-line
    if (!use_log) (r = m(P * f.r + prst * t.r)), (g = m(P * f.g + prst * t.g)), (b = m(P * f.b + prst * t.b));
    //@ts-ignore
    // eslint-disable-next-line
    else (r = m((P * f.r ** 2 + prst * t.r ** 2) ** 0.5)), (g = m((P * f.g ** 2 + prst * t.g ** 2) ** 0.5)), (b = m((P * f.b ** 2 + prst * t.b ** 2) ** 0.5));
    //@ts-ignore
    // eslint-disable-next-line
    (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * prst) : 0);
    if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
    else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
};