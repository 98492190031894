import React, {useEffect, useRef, useLayoutEffect, useState} from 'react';
import {ComponentContext} from './data';
import {OverlayProps, ComponentProps} from './model';
import {css, CreateScheet} from 'packages/aphrodite/aphrodite';
import Colors from 'src/themes/colors';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';

/* PEASE USER styles PROPS TO MODIFY WIDTH */

export default function Overlay<D>({
    close,
    order,
    styles,
    content,
    prevent,
    selector,
    Component,
    overlayClose,
    disableAnimation,
}: OverlayProps<D>) {
    const wrapper = useRef<HTMLDialogElement>(null);
    const [show, setShow] = useState(disableAnimation);
    const Styles = ComponentStyles();

    function onKeydown(e: KeyboardEvent) {
        if (!prevent || (!prevent.internalClose && !prevent.closeOnESC)) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }
    }

    function onClickOverlay(e: React.MouseEvent) {
        if (!prevent || (!prevent.internalClose && !prevent.closeOnOverlay)) {
            if (wrapper.current && e.target instanceof HTMLElement) {
                if (!wrapper.current.contains(e.target)) {
                    handleClose();
                }
            }
        }
    }

    function onPopstate() {
        if (!prevent || (!prevent.internalClose && !prevent.closeOnPopstate)) {
            handleClose();
        }
    }

    function handleClose() {
        if (disableAnimation) {
            close();
        } else {
            setShow(false);
            setTimeout(() => {
                close();
            }, 200);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeydown);
        window.addEventListener('popstate', onPopstate);
        return () => {
            window.removeEventListener('keydown', onKeydown);
            window.removeEventListener('popstate', onPopstate);
        };
    });

    useLayoutEffect(() => {
        if (!disableAnimation) {
            setTimeout(() => {
                setShow(true);
            }, 50);
        }
    }, [disableAnimation]);

    const componentProps: ComponentProps<D> = {
        close: handleClose,
        selector: selector,
        content: content,
    };

    return (
        <aside
            {...{
                id: `_lightbox-${selector}`,
                className: css(
                    Styles.overlay,
                    GetZIndex(order),
                    styles && styles.overlay ? styles.overlay : null,
                    show ? Styles.showOverlay : null
                ),
                onMouseDown: onClickOverlay,
            }}
        >
            {overlayClose ? (
                <aside
                    className={css(Styles.overlayClose)}
                    onClick={handleClose}
                >
                    <CrossIcon
                        className={css(Styles.overlayCloseIcon)}
                        width="16"
                        height="16"
                    />
                </aside>
            ) : null}
            <section
                {...{
                    id: `_lightbox-${selector}-wrapper`,
                    ref: wrapper,
                    role: 'dialog',
                    'aria-labelledby': `_lightbox-${selector}-label`,
                    'aria-modal': true,
                    className: css(
                        Styles.wrapper,
                        styles && styles.wrapper ? styles.wrapper : null,
                        show ? Styles.showWrapper : null
                    ),
                }}
            >
                <ComponentContext.Provider
                    {...{
                        value: {
                            selector,
                        },
                    }}
                >
                    <Component {...componentProps} />
                </ComponentContext.Provider>
            </section>
        </aside>
    );
}

const GetZIndex = (order: number) => {
    return CreateScheet({
        base: {
            zIndex: 1298 + order,
        },
    }).base;
};

const ComponentStyles = () => {
    const theme = Colors();

    return CreateScheet({
        overlay: {
            position: 'fixed',
            background: 'rgba(0, 0, 0, 0.5)',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            opacity: 0,
            transition: 'all 0.2s',
            transform: 'translateZ(31px)',
        },
        showOverlay: {
            opacity: 1,
        },
        wrapper: {
            width: '600px',
            maxHeight: '100vh',
            maxWidth: '95%',
            display: 'flex',
            flexDirection: 'column',
            transform: 'translateY(-20px)',
            transition: 'transform 0.2s',
        },
        showWrapper: {
            transform: 'translateY(0px)',
        },
        overlayClose: {
            position: 'absolute',
            top: '50%',
            right: 20,
            width: 16,
            height: 16,
            marginTop: -8,
            color: theme.get_color('primaryContrast'),
            cursor: 'pointer',
        },
        overlayCloseIcon: {
            fill: theme.get_color('primaryContrast'),
        },
    });
};
