const Colors = {
    primary: '#fff',
    primarySecondary: '#e4e4e4',
    primaryContrast: '#000000',
    lighter: '#808080',
    light: '#504f4f',
    disabled: '#C3C3C3',

    bg: '#202124',
    bgSecondary: '#121212',
    bgBlock: '#363636',
    bgBlockLighter: '#464646',
    bgBlockContrast: '#e4e4e4',
    bgHover: '#474747',

    boxShadowBlock:
        '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',

    border: 'rgba(54, 54, 54, 0.1)',
    borderForm: '#ffffff',

    warning: '#FEFFD9',
    success: '#CDF4CD',
    danger: '#FFAFAF',
    dangerBright: '#FF4949',

    brand: '#D9EDFF',
    darkBrand: '#2665C3',
    protocol: '#D9EDFF',
    command: '#ECECEC',
    equipment: '#CDC3FF',
    software: '#D0FAED',
    dataset: '#F3C3F4',

    tableBtnShadow: 'rgba(37, 37, 37, 0.2)',
    tableBtnCellBg: '#F4F4F4',
    tableBtnCellBorder: '#E8E8E8',
    tableBtnSelectedBg: '#C3C3C3',
    tableBtnSelectedBorder: '#808080',

    legendText: '#b0b0b0',

    draftVideShadowColor: 'rgba(48, 48, 48, 0.2)',

    interactBg: "#363636"
};

export default Colors;
