//import { history, OnHistoryChange } from "packages/history/history";
import Renderer from 'packages/lightbox/ModalRoot.react';
import {Fragment, useEffect, useState} from 'react';
import RouterApp from './router.react';
import ReactTooltip from 'react-tooltip';

export default function App() {
    const [Loading, SetLoading] = useState(true);

    useEffect(() => {
        async function init() {
            try {
                SetLoading(false);

                //OnHistoryChange(history.location, true)
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, []);

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <Renderer />
            <RouterApp />
            <ReactTooltip clickable />
        </Fragment>
    );
}
