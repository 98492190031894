import { useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import {
    getEnabledLanguages,
    getLoadedLanguages,
    setLoadedLanguage,
} from './languages'
import useOnce from 'packages/hooks/useOnce'

// set DEBUG to TRUE if u want to see localization logs
const DEBUG: boolean = false
const namespaces: string[] = []


interface Catalog {
    code: string
    resources: object
}

// log works only if debug mode is enabled otherwise it does nothing
const log = (input: string) => {if (DEBUG) console.log('localization:', input)}

// dynamically imports JSONs as chunks in webpack
const importCatalogs = (path: string) => {
    return import(
        /* webpackChunkName: "loc-[request]" */
        `locale/namespaces/${path}`
    )
}

// loads resources for namespace and language
const loadResources = async (namespace: string, code: string) => {
    // check if the language is enabled
    if (!getEnabledLanguages().includes(code)) {
        throw new Error('ERROR: trying to load resources for disabled or not existing language')
    }

    try {
        const catalog: Catalog = await importCatalogs(`${namespace}/${code}.json`)
        i18next.addResourceBundle(catalog.code, namespace, catalog.resources)
        setLoadedLanguage(code)
        log(`loaded resources for namespace ${namespace} language ${code}`)
    } catch(error) {
        // TODO: add sentry
        console.log(error) // throw error is commented to be able to see tokens
    }

}

// changes language and loads resources for loaded namespaces for new language 
const changeLanguage = async (code: string) => {
    // check if new language is enabled
    if (!getEnabledLanguages().includes(code)) {
        throw new Error('ERROR: changing language to disabled or not existing one')
    }

    // check if new language is already loaded
    if (!getLoadedLanguages().includes(code)) {
        // load new language's resources for all registered namespaces
        try {
            await Promise.all(namespaces.map((namespace) => {
                return loadResources(namespace, code)
            }))
        } catch(error) {
            // TODO: add sentry
            throw error
        }
    }

    // finally, change the language
    i18next.changeLanguage(code)
}

interface Localization {
    t: any
    tReady: boolean
}

// hook for function components 
const useLocalization = (namespace: string): Localization => {
    const [ tReady, setTReady ] = useState(false)

    let { t } = useTranslation(namespace)

    useOnce(() => {
        const loadLang = async () => {
            await initLocalization(namespace)
            setTReady(true)
        }

        loadLang()
    })

    if (!tReady) {
        t = () => { return "" };
    }

    return { t, tReady }
}

// inits the localization
const initLocalization = async (namespace: string) => {
    // prevent multiple initialization
    if (namespaces.includes(namespace)) {
        log(`namespace ${namespace} is already initialized`)
        return
    }

    // load resources for the new namespace for detected language
    try {
        // load resources for EN anyway to be able to fallback
        await loadResources(namespace, 'en')
        
        // load resources for current language
        if (i18next.language !== 'en') {
            await loadResources(namespace, i18next.language)
        }
        
        // register a new namespace by adding it to the namespaces array
        if (!namespaces.includes(namespace)) {
            namespaces.push(namespace)
        }

        log(`initialized namespace ${namespace}`)
    } catch(error) {
        // TODO: add sentry
        throw error
    }
}

// handles language detection
const handleDetectedLanguage = () => {    
    const locale = i18next.language.split('-')[0]

    // check if detected language is enabled
    if (!getEnabledLanguages().includes(locale)) {
        log(`detected language ${locale} is disabled or not provided at all`)
        i18next.changeLanguage('en')
        return
    }

    i18next.changeLanguage(locale)
}

// t function
const t = (key: string) => {
    return i18next.t(key)
}

// t function binded for namespace
const getFixedT = (namespace: string) => {
    return i18next.getFixedT(null, namespace)
}

// checks if key exists in resources
const keyExists = (namespace: string, key: string, language: string) => {
    return i18next.exists(`${namespace}:${key}`, { lng: language })
}

const getLocale = () => i18next.language.split('-')[0]

export {
    changeLanguage,
    getFixedT,
    useLocalization,
    initLocalization,
    handleDetectedLanguage,
    t,
    keyExists,
    getLocale,
}
