import { ComponentType } from 'react';
import { Lightbox, Header, Body, Footer, ComponentProps, showLightbox } from './lightbox';
import { CreateScheet} from 'aphrodite';
import CreateGUID from 'packages/helpers/CreateGUID';

/* 
    buttons is an array of html button props with extra attr: text which can be string or JSX lement
    examples:
    1. buttons: [{ text: "close", onClick: (e) => { my on click logic } }]
    2. buttons: [{ text: "close", className: "my-class-name" }]
    3. buttons: [{ text: (<div>jsx inside</div>), className: "my-class-name" }]
*/

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type Button = ButtonProps & { text: string | JSX.Element }

interface config {
    guid?:      string
    title?:     string,
    buttons?:   Array<Button>,
    OnClose?:   () => void,
}

type props = config & {
    body:       ComponentType | JSX.Element | string,
}

export default function showWarning(Component: ComponentType | JSX.Element | string, config?: config) {
    const selector = config?.guid ? config.guid : CreateGUID();

    showLightbox<props>({
        selector:   selector,
        Component:  Warning,
        content:    {
            ...config,
            body: Component,
        }
    })

    return selector;
}

export function showErrorLightbox(err:string) {
    showWarning(
        <div>Error: {err}</div>,
        {buttons: [{
            text: "Close",
        }]}
    )
}


function Warning({
    content,
    close,
}:ComponentProps<props>) {
    const Componet = content.body;

    return (
        <Lightbox {...{ close }}>
            { content.title && (
                <Header>
                    { content.title }
                </Header>
            ) }
            <Body>
                { typeof Componet === "function" ? <Componet /> : Componet }
            </Body>
            <Footer {...{ styles: Styles.footer }}>
                { content.buttons && content.buttons.map(({ text, ...button }, i) => (
                    <button {...button} {...{
                        key: i,
                        onClick: (e) => {
                            close();
                            if (button.onClick) button.onClick(e);
                        }
                    }}>
                        { text }
                    </button>
                )) }
            </Footer>
        </Lightbox>
    )
}

const Styles = CreateScheet({
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        transition: "all 0.1"
    }
});