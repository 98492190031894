import {useContext} from 'react';
import {LightboxProps} from './model';
import {css, CreateScheet} from 'packages/aphrodite/aphrodite';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import Colors from 'src/themes/colors';
import {ComponentContext} from './data';

export function Lightbox({close, styles, ...props}: LightboxProps) {
    const Styles = ComponentStyles();

    return (
        <article
            {...props}
            {...{
                className: css(Styles._lightbox, styles ? styles : null),
            }}
        >
            {typeof close === 'function' ? (
                <aside
                    className={css(Styles._lightboxClose)}
                    onClick={close}
                >
                    <CrossIcon
                        className={css(Styles._lightboxCloseIcon)}
                        width="20"
                        height="20"
                    />
                </aside>
            ) : null}
            {props.children}
        </article>
    );
}

export function Body({styles, ...props}: LightboxProps) {
    const Styles = ComponentStyles();

    return (
        <section
            {...props}
            {...{
                className: css(Styles._body, styles ? styles : null),
            }}
        >
            {props.children}
        </section>
    );
}

export function Header({styles, ...props}: LightboxProps) {
    const {selector} = useContext(ComponentContext);
    const Styles = ComponentStyles();

    return (
        <header
            {...props}
            {...{
                id: selector ? `_lightbox-${selector}-label` : undefined,
                className: css(Styles._header, styles ? styles : null),
            }}
        >
            {props.children}
        </header>
    );
}

export function Footer({styles, ...props}: LightboxProps) {
    const Styles = ComponentStyles();

    return (
        <footer
            {...props}
            {...{
                className: css(Styles._footer, styles ? styles : null),
            }}
        >
            {props.children}
        </footer>
    );
}

const ComponentStyles = () => {
    const theme = Colors();

    return CreateScheet({
        _lightbox: {
            backgroundColor: theme.get_color('bgBlock'),
            color: theme.get_color('primary'),
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        _lightboxClose: {
            position: 'absolute',
            top: 20,
            right: 20,
            width: 16,
            height: 16,
            marginTop: -8,
            color: theme.get_color('primaryContrast'),
            cursor: 'pointer',
            zIndex: 2,
        },
        _lightboxCloseIcon: {
            fill: theme.get_color('primaryContrast'),
        },
        _header: {
            position: 'relative',
            fontFamily: "'Inter', sans-serif",
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'left',
            margin: 0,
            padding: '10px 20px',
            lineHeight: '1.4',
            color: theme.get_color('primaryContrast'),
            background: theme.get_color('bgBlockContrast'),
        },
        _body: {
            position: 'relative',
            margin: 0,
            padding: '10px 20px',
            lineHeight: '1.4',
            color: theme.get_color('primary'),
            background: theme.get_color('bgBlock'),
            flexGrow: 1,
        },
        _footer: {
            display: 'flex',
            padding: '10px 20px',
            justifyContent: 'flex-end',
        },
    });
};
