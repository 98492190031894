const Colors = {
    primary: '#000',
    primarySecondary: '#363636',
    primaryContrast: '#ffffff',
    lighter: '#808080',
    light: '#ECECEC',
    disabled: '#C3C3C3',

    bg: '#ffffff',
    bgSecondary: '#ececec',
    bgBlock: '#ffffff',
    bgBlockLighter: '#ffffff',
    bgBlockContrast: '#363636',
    bgHover: '#EFEFEF',

    boxShadowBlock: '0px 10px 20px rgba(0, 0, 0, 0.3)',

    border: '#C3C3C3',
    borderForm: '#B0B0B0',

    warning: '#FEFFD9',
    success: '#CDF4CD',
    danger: '#FFAFAF',
    dangerBright: '#FF4949',

    brand: '#D9EDFF',
    darkBrand: '#2665C3',
    protocol: '#D9EDFF',
    command: '#ECECEC',
    equipment: '#CDC3FF',
    software: '#D0FAED',
    dataset: '#F3C3F4',

    tableBtnShadow: 'rgba(37, 37, 37, 0.2)',
    tableBtnCellBg: '#F4F4F4',
    tableBtnCellBorder: '#E8E8E8',
    tableBtnSelectedBg: '#C3C3C3',
    tableBtnSelectedBorder: '#808080',

    legendText: '#b0b0b0',

    draftVideShadowColor: 'rgba(48, 48, 48, 0.2)',

    interactBg: "#F4F4F4"
};

export default Colors;
